import {
  getWebApiURL,
  getWebApiURLForTenant,
  getRestApiURL,
  getInternalAdminApiPrefix,
} from '@qb/frontend/utils/hosts/apiHost';
import { TenantAlias } from '@/shared/tenants-alias-map';
import {
  AddressCreateAddressResponse,
  AddressUpdateAddressResponse,
  AddressCreateAddressPayload,
  AddressUpdateAddressPayload,
  AddressFindAddressResponse,
} from '@/shared/types/controllers/AddressControllerTypes';
import {
  LogFrontendPageLoadPayload,
  LogFrontendErrorPayload,
} from '@/shared/types/controllers/AnalyticsControllerTypes';
import { GetAppResponse } from '@/shared/types/controllers/AppControllerTypes';
import {
  CurrentUserInfoResponse,
  ResetPasswordPayload,
  LoginPayload,
  LoginResponse,
} from '@/shared/types/controllers/AuthControllerTypes';
import { CartDisplayDataResponse } from '@/shared/types/controllers/CartControllerTypes';
import {
  UpdateCartPartResponse,
  UpdateCartPartRequest,
  UpsertCartPartViaPartSupplierResponse,
  BatchUpsertCartPartViaPartSupplierPayloadData,
  UpsertCartPartsViaQuoteResponse,
  UpsertCartPartViaQuotePayload,
} from '@/shared/types/controllers/CartPartControllerTypes';
import {
  CertificateUploadRequest,
  CertificateFindCertificatesResponse,
  CertificateFindCertificatesPayload,
  CertificateGetCertificateListResponse,
  CertificateGetCertificateListRequestQueryParams,
  CertificateSetCertificateApprovalPayload,
} from '@/shared/types/controllers/CertificateControllerTypes';
import {
  CompanyLogoResponse,
  CompanySettingsMarketplaceDataResponse,
  CompanySettingsSupplierDataResponse,
  GetSupportedManufacturerDataRequestParams,
  GetSupportedManufacturerDataResponse,
  GetSupportedManufacturersSettingsListResponse,
} from '@/shared/types/controllers/CompanyControllerTypes';
import {
  ConversationHasUnreadConversationResponse,
  ConversationDisplayDataResponse,
  ConversationCreateMessagePayload,
  ConversationCreatePartMessagePayload,
  ConversationCreateQuoteMessagePayload,
  ConversationListDataPayload,
  ConversationListDataResponse,
  ConversationSearchResponse,
  ConversationSearchPayload,
} from '@/shared/types/controllers/ConversationControllerTypes';
import {
  CouponCodeCreateCouponCodePayload,
  CouponCodeCreateCouponCodeResponse,
  CouponCodeListCouponCodesResponse,
  CouponCodeUpdateCouponCodePayload,
  CouponCodeValidateCouponCodePayload,
  CouponCodeValidateCouponCodeResponse,
} from '@/shared/types/controllers/CouponCodeControllerTypes';
import {
  BuyerDashboardDataResponse,
  SupplierDashboardDataResponse,
} from '@/shared/types/controllers/DashboardControllerTypes';
import { ExternalDocumentLinkResponse } from '@/shared/types/controllers/ExternalDocumentControllerTypes';
import {
  IAPartSearchExternalPartByPartIdResponse,
  IAPartSearchExternalPartByQueryResponse,
  IAPartSearchSalesDataResponse,
} from '@/shared/types/controllers/IAPartSearchControllerTypes';
import { IAUserEnrichmentInfoResponse } from '@/shared/types/controllers/IAUserEnrichmentControllerTypes';
import { GetErpShippingMethodsResponse } from '@/shared/types/controllers/IntegrationControllerTypes';
import { NavbarGetDataResponse } from '@/shared/types/controllers/NavbarControllerTypes';
import { NotificationPolicyUnsubscribePayload } from '@/shared/types/controllers/NotificationPolicyControllerTypes';
import {
  OrderReceivedOrdersResponse,
  OrderPlacedOrdersResponse,
  OrderReceivedOrdersQueryParams,
  OrderPlacedOrdersQueryParams,
  OrderDisplayDataResponse,
  SetManualSupplierIntegrationPayload,
  OrderCustomerDetailsResponse,
  OrderRecentBuyerOrdersResponse,
  OrderRecentBuyerOrdersPayload,
  SetTaxAndShippingOverridePayload,
  OrderDenyPayload,
  OrderPartialShipPayload,
  OrderUpdateSupplierNotesPayload,
  OrderGetIntegrationExceptionsResponse,
  OrderUpdateShippingMethodPayload,
} from '@/shared/types/controllers/OrderControllerTypes';
import {
  OrderItemUpdateInstanceBuyerPayload,
  OrderItemUpdateInstanceSupplierPayload,
} from '@/shared/types/controllers/OrderItemControllerTypes';
import {
  PartCategoryFindOnePartCategoryResponse,
  PartCategoryFooterCategoriesResponse,
  PartCategorySearchCategoriesResponse,
  PartCategoryTrendingCategoriesResponse,
} from '@/shared/types/controllers/PartCategoryControllerTypes';
import {
  PartAvailabilityForSupplierResponse,
  PartPopularPartsResponse,
  PartPublicDataResponse,
  PartPublicQtyAndPriceResponse,
  PartDisplayDataResponse,
  PartRelevantPartsResponse,
  PartGetShippingRatesRequest,
  PartGetShippingRatesResponse,
} from '@/shared/types/controllers/PartControllerTypes';
import {
  PartRequestCreatePartRequestPayload,
  PartRequestUpdatePriceAndQtyPayload,
  PartRequestUpdateRankingPayload,
} from '@/shared/types/controllers/PartRequestControllerTypes';
import { PasswordTokenFindOneResponse } from '@/shared/types/controllers/PasswordTokenControllerTypes';
import {
  PaymentPaymentMethodsResponse,
  PaymentPaymentSetupDataResponse,
} from '@/shared/types/controllers/PaymentControllerTypes';
import { PendingUserSetReferralUrlPayload } from '@/shared/types/controllers/PendingUserControllerTypes';
import {
  QuoteCreateMultipleWithPartPayload,
  QuoteCreateMultipleWithPartResponse,
  QuoteDisplayDataResponse,
  QuoteListBuyerDataPayload,
  QuoteListBuyerDataResponse,
  QuoteListSupplierDataPayload,
  QuoteListSupplierDataResponse,
  QuoteNoBidPayload,
  QuoteRecentBuyerQuotesPayload,
  QuoteRecentBuyerQuotesResponse,
  QuoteRecentContactQuotesPayload,
  QuoteRecentContactQuotesResponse,
  QuoteSubscribePayload,
  QuoteUnsubscribePayload,
  QuoteUpdateAssigneePayload,
  QuoteUpdateNotesSupplierPayload,
  QuoteUpdateQuoteNotePayload,
  QuoteUpdateValidityDurationPayload,
} from '@/shared/types/controllers/QuoteControllerTypes';
import {
  QuoteFeeCreateQuoteFeePayload,
  QuoteFeeCreateQuoteFeeResponse,
  QuoteFeeUpdateQuoteFeePayload,
  QuoteFeeUpdateQuoteFeeResponse,
} from '@/shared/types/controllers/QuoteFeeControllerTypes';
import { SalesTerritorySetSalesTerritoryBatchPayload } from '@/shared/types/controllers/SalesTerritoryControllerTypes';
import {
  SearchPartAutocompleteOmniResponse,
  SearchPartCategoryDataResponse,
  SearchParametricPartDataResponse,
  SearchNonparametricPartDataResponse,
  SearchPartAutocompleteResponse,
  SearchPartCategoryAutocompleteOmniResponse,
  SearchNonparametricPartDataPayload,
  SearchParametricPartDataPayload,
  SearchPartSearchTableDataResponse,
  SearchPartSearchTableDataPayload,
} from '@/shared/types/controllers/SearchControllerTypes';
import {
  ShippingAccountFindShippingAccountResponse,
  ShippingAccountCreateShippingAccountPayload,
  ShippingAccountCreateShippingAccountResponse,
} from '@/shared/types/controllers/ShippingAccountControllerTypes';
import {
  SupplierPriceAddPriceForPartResponse,
  SupplierPriceAddPriceForPartPayload,
  SupplierPriceUploadPartsPricesFilePayload,
} from '@/shared/types/controllers/SupplierPriceControllerTypes';
import {
  GetProfileImageResponse,
  UserUpdateUserPayload,
  UserUpdateUserResponse,
} from '@/shared/types/controllers/UserControllerTypes';
import { UserInviteTokenFindOneUserInviteTokenResponse } from '@/shared/types/controllers/UserInviteTokenControllerTypes';
import { UserPaymentMethodCreateUserPaymentMethodPayload } from '@/shared/types/controllers/UserPaymentMethodController';
import {
  CreateConversationAuthenticatedPayload,
  CreateConversationAuthenticatedResponse,
  CreateConversationUnauthenticatedPayload,
  CreateConversationUnauthenticatedResponse,
  CreateMessageAuthenticatedPayload,
} from '@/shared/types/controllers/WidgetControllerTypes';
import {
  QuoteCreateAsAnonymousPayload,
  QuoteCreateAsAnonymousResponse,
} from '@/shared/types/controllers/quote/create-as-anonymous';
import {
  deleteRequestPromise,
  getRequestPromise,
  postRequestPromise,
  uploadRequestPromise,
} from './RequestPromise';
import {
  getSocketRequestPromise,
  postSocketRequestPromise,
  appendParamsToUrl,
} from './SocketRequestPromise';

export const webQueries = {
  app: {
    get: (appId: number) => ({
      queryKey: ['app', appId],
      queryFn: () =>
        getRequestPromise<GetAppResponse>(`${getRestApiURL()}/app/${appId}`),
    }),
  },
  auth: {
    login: {
      mutationFn: (payload: LoginPayload) =>
        postRequestPromise<LoginResponse, LoginPayload>(
          `${getWebApiURL()}/login`,
          payload,
        ),
    },
    logout: {
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/logout`,
          undefined,
          'Unable to logout',
        ),
    },
  },
  analytics: {
    logFrontendError: {
      mutationKey: ['analytics', 'logFrontendError'],
      mutationFn: (payload: LogFrontendErrorPayload) =>
        postRequestPromise<void, LogFrontendErrorPayload>(
          `${getWebApiURL()}/analytics/logFrontendError`,
          payload,
        ),
    },
    logPageLoad: {
      mutationKey: ['analytics', 'logPageLoad'],
      mutationFn: (payload: LogFrontendPageLoadPayload) =>
        postRequestPromise<void, LogFrontendPageLoadPayload>(
          `${getWebApiURL()}/analytics/logPageLoad`,
          payload,
        ),
    },
  },
  company: {
    logo: (companyId: number) => ({
      queryKey: ['company', 'logo', companyId],
      queryFn: () =>
        getRequestPromise<CompanyLogoResponse>(
          `${getWebApiURL()}/company/${companyId}/logo`,
        ).then((data) => data.url),
    }),
    supportManufacturer: {
      mutationFn: (manufacturerID: number) =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/company/${manufacturerID}/supportManufacturer`,
        ),
    },
    unsupportManufacturer: {
      mutationFn: (manufacturerID: number) =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/company/${manufacturerID}/unsupportManufacturer`,
        ),
    },
    settings: {
      marketplaceData: {
        queryKey: ['company', 'settings', 'marketplace'],
        queryFn: () =>
          getRequestPromise<CompanySettingsMarketplaceDataResponse>(
            `${getWebApiURL()}/company/settings/marketplaceData`,
          ),
      },
      certificates: {
        queryKey: ['company', 'settings', 'certificates'],
        certificateList: (
          queryParams: CertificateGetCertificateListRequestQueryParams,
        ) => ({
          queryKey: [
            'company',
            'settings',
            'certificates',
            'certificateList',
            queryParams,
          ],
          queryFn: () =>
            getRequestPromise<CertificateGetCertificateListResponse>(
              `${getWebApiURL()}/company/settings/certificateList`,
              { params: queryParams },
            ),
        }),
      },
      certificateApproval: {
        mutationFn: (payload: CertificateSetCertificateApprovalPayload) =>
          postRequestPromise<void, CertificateSetCertificateApprovalPayload>(
            `${getWebApiURL()}/company/settings/certificateApproval`,
            payload,
          ),
      },
      supplierData: {
        queryKey: ['company', 'settings', 'supplierData'],
        queryFn: () =>
          getRequestPromise<CompanySettingsSupplierDataResponse>(
            `${getWebApiURL()}/company/settings/supplierData`,
          ),
      },
      supportedManufacturerData: (
        params: GetSupportedManufacturerDataRequestParams,
      ) => ({
        queryKey: ['company', 'settings', 'supportedManufacturerData', params],
        queryFn: () =>
          getRequestPromise<GetSupportedManufacturerDataResponse>(
            `${getWebApiURL()}/company/settings/supportedManufacturerData`,
            { params },
          ),
      }),
      supportedManufacturersList: {
        queryKey: ['company', 'settings', 'supportedManufacturersList'],
        queryFn: () =>
          getRequestPromise<GetSupportedManufacturersSettingsListResponse>(
            `${getWebApiURL()}/company/settings/supportedManufacturersList`,
          ),
      },
    },
  },
  dashboard: {
    buyerData: {
      queryKey: ['dashboard', 'buyerData'],
      queryFn: () =>
        getRequestPromise<BuyerDashboardDataResponse>(
          `${getWebApiURL()}/buyerDashboardData`,
        ),
    },
    supplierData: {
      queryKey: ['dashboard', 'supplierData'],
      queryFn: () =>
        getRequestPromise<SupplierDashboardDataResponse>(
          `${getWebApiURL()}/supplierDashboardData`,
        ),
    },
  },
  user: {
    queryKey: ['user'],
    getProfileImage: (userId: number | undefined) => ({
      queryKey: ['user', 'getProfileImage', userId],
      queryFn: () =>
        getRequestPromise<GetProfileImageResponse>(
          `${getWebApiURL()}/user/${userId}/getProfileImage`,
        ).then((data) => data.url),
    }),
    getCurrentUserInfo: () => ({
      queryKey: ['user', 'info'],
      queryFn: () =>
        getRequestPromise<CurrentUserInfoResponse>(
          `${getWebApiURL()}/user/info`,
        ),
    }),
    updateUser: (userID: number) => ({
      mutationFn: (payload: UserUpdateUserPayload) =>
        postRequestPromise<UserUpdateUserResponse, UserUpdateUserPayload>(
          `${getWebApiURL()}/user/${userID}`,
          payload,
        ),
    }),
  },
  conversation: {
    hasUnreadConversation: {
      queryKey: ['conversation', 'hasUnreadConversation'],
      queryFn: () =>
        getSocketRequestPromise<
          void,
          ConversationHasUnreadConversationResponse
        >(`${getWebApiURL()}/conversation/hasUnreadConversation`).then(
          (data) => data.hasUnreadConversation,
        ),
    },
    displayData: (conversationId: number) => ({
      queryKey: ['conversation', 'displayData', conversationId],
      queryFn: () =>
        getSocketRequestPromise<void, ConversationDisplayDataResponse>(
          `${getWebApiURL()}/conversation/${conversationId}/displayData`,
        ).then((data) => data?.conversation),
      enabled: Boolean(conversationId),
    }),
    search: (params: ConversationSearchPayload) => ({
      queryKey: ['conversation', 'search', params],
      queryFn: () =>
        getRequestPromise<ConversationSearchResponse>(
          `${getWebApiURL()}/conversation/search`,
          { params },
        ).then((res) => res.searchConversationResults),
    }),
    listData: {
      queryKey: ['conversation', 'listData'],
      queryFn: (payload: ConversationListDataPayload) =>
        getSocketRequestPromise<
          ConversationListDataPayload,
          ConversationListDataResponse
        >(`${getWebApiURL()}/conversation/listData`, payload).then(
          (res) => res?.conversations,
        ),
    },
    createMessage: (conversationId: number) => ({
      mutationKey: ['conversation', 'createMessage'],
      mutationFn: (payload: ConversationCreateMessagePayload) =>
        postSocketRequestPromise<ConversationCreateMessagePayload, void>(
          `${getWebApiURL()}/conversation/${conversationId}/createMessage`,
          payload,
        ),
    }),
    createPhotoOrFileMessage: (conversationId: number) => ({
      mutationKey: ['conversation', 'createFileMessage', 'createPhotoMessage'],
      mutationFn: (file: File) => {
        if (file.type.startsWith('image/')) {
          return uploadRequestPromise<void, { file: File }>(
            `${getWebApiURL()}/conversation/${conversationId}/createPhotoMessage`,
            { file },
          );
        }
        return uploadRequestPromise<void, { file: File }>(
          `${getWebApiURL()}/conversation/${conversationId}/createFileMessage`,
          { file },
        );
      },
    }),
    createConversationAuthenticated: {
      mutationKey: ['conversation', 'createConversationAuthenticated'],
      mutationFn: (payload: CreateConversationAuthenticatedPayload) =>
        postSocketRequestPromise<
          CreateConversationAuthenticatedPayload,
          CreateConversationAuthenticatedResponse
        >(`${getWebApiURL()}/widget/createConversationAuthenticated`, payload),
    },
    createConversationUnauthenticated: {
      mutationKey: ['conversation', 'createConversationUnauthenticated'],
      mutationFn: (payload: CreateConversationUnauthenticatedPayload) => {
        return postSocketRequestPromise<
          CreateConversationUnauthenticatedPayload,
          CreateConversationUnauthenticatedResponse
        >(
          `${getWebApiURL()}/widget/createConversationUnauthenticated`,
          payload,
        );
      },
    },
    createMessageAuthenticated: {
      mutationKey: ['conversation', 'createMessageAuthenticated'],
      mutationFn: (payload: CreateMessageAuthenticatedPayload) => {
        return postSocketRequestPromise<
          CreateMessageAuthenticatedPayload,
          void
        >(`${getWebApiURL()}/widget/createMessageAuthenticated`, payload);
      },
    },
    createPartMessage: (conversationId: number) => ({
      mutationKey: ['conversation', 'createPartMessage'],
      mutationFn: (payload: ConversationCreatePartMessagePayload) => {
        return postSocketRequestPromise<
          ConversationCreatePartMessagePayload,
          void
        >(
          `${getWebApiURL()}/conversation/${conversationId}/createPartMessage`,
          payload,
        );
      },
    }),
    createQuoteMessage: (conversationId: number) => ({
      mutationKey: ['conversation', 'createQuoteMessage'],
      mutationFn: (payload: ConversationCreateQuoteMessagePayload) => {
        return postSocketRequestPromise<
          ConversationCreateQuoteMessagePayload,
          void
        >(
          `${getWebApiURL()}/conversation/${conversationId}/createQuoteMessage`,
          payload,
        );
      },
    }),
    join: () => ({
      mutationKey: ['conversation', 'join'],
      mutationFn: (conversationId: number) => {
        return postRequestPromise<void, void>(
          `${getWebApiURL()}/conversation/${conversationId}/join`,
          undefined,
        );
      },
    }),
    typing: (conversationId: number) => ({
      mutationKey: ['conversation', 'typing'],
      mutationFn: () =>
        postSocketRequestPromise(
          `${getWebApiURL()}/conversation/${conversationId}/typing`,
        ),
    }),
  },
  part: {
    publicData: (partId: number) => ({
      queryKey: ['part', 'publicData', partId],
      queryFn: () =>
        getRequestPromise<PartPublicDataResponse>(
          `${getWebApiURL()}/part/${partId}/publicData`,
        ),
    }),
    displayData: (partId: number) => ({
      queryKey: ['part', 'displayData', partId],
      queryFn: () =>
        getRequestPromise<PartDisplayDataResponse>(
          `${getWebApiURL()}/part/${partId}/displayData`,
        ),
    }),
    relevantParts: (partId: number, timeout = 5000) => ({
      queryKey: ['part', 'relevantParts', partId],
      queryFn: () =>
        getRequestPromise<PartRelevantPartsResponse>(
          `${getWebApiURL()}/part/${partId}/relevantParts`,
          {
            timeout: timeout,
          },
        ).then((res) => res.relevantParts),
    }),
    shippingRates: (partID: number, params: PartGetShippingRatesRequest) => ({
      queryKey: ['part', 'shippingRates', partID, params],
      queryFn: () =>
        getRequestPromise<PartGetShippingRatesResponse>(
          `${getWebApiURL()}/part/${partID}/shippingRates`,
          { params },
        ),
    }),
    popularParts: {
      queryKey: ['part', 'popularParts'],
      queryFn: () =>
        getRequestPromise<PartPopularPartsResponse>(
          `${getWebApiURL()}/part/popularParts`,
          {
            timeout: 5000,
          },
        ).then((res) => res.parts),
    },
    availabilityForSupplier: (partID: number, supplierID: number) => ({
      queryKey: ['part', 'availabilityForSupplier', partID, supplierID],
      queryFn: () =>
        getRequestPromise<PartAvailabilityForSupplierResponse>(
          `${getWebApiURL()}/part/${partID}/availabilityForSupplier`,
          { params: { supplierID } },
        ),
    }),
    publicQtyAndPrice: (
      partID: number,
      recordViewToAnalytics: boolean,
      utmSource: string | null,
    ) => ({
      queryKey: ['part', 'publicQtyAndPrice', partID],
      queryFn: () =>
        getRequestPromise<PartPublicQtyAndPriceResponse>(
          `${getWebApiURL()}/part/${partID}/publicQtyAndPrice`,
          {
            params: {
              recordViewToAnalytics: recordViewToAnalytics,
              utmSource: utmSource,
            },
            timeout: 5000,
          },
        ),
    }),
  },
  partRequest: {
    queryKey: ['partRequest'],
    create: () => ({
      mutationKey: ['partRequest', 'create'],
      mutationFn: (payload: PartRequestCreatePartRequestPayload) =>
        postRequestPromise<void, PartRequestCreatePartRequestPayload>(
          `${getRestApiURL()}/partRequest`,
          payload,
        ),
    }),
    remove: () => ({
      mutationKey: ['partRequest', 'remove'],
      mutationFn: (partRequestID: number) =>
        deleteRequestPromise<void>(
          `${getRestApiURL()}/partRequest/${partRequestID}`,
        ),
    }),
    updateRanking: () => ({
      mutationKey: ['partRequest', 'updateRanking'],
      mutationFn: ({
        partRequestID,
        payload,
      }: {
        partRequestID: number;
        payload: PartRequestUpdateRankingPayload;
      }) =>
        postRequestPromise<void, PartRequestUpdateRankingPayload>(
          `${getWebApiURL()}/partRequest/${partRequestID}/updateRanking`,
          payload,
        ),
    }),
    updatePriceAndQty: (partRequestID: number) => ({
      mutationKey: ['partRequest', partRequestID, 'updatePriceAndQty'],
      mutationFn: (payload: PartRequestUpdatePriceAndQtyPayload) =>
        postRequestPromise<void, PartRequestUpdatePriceAndQtyPayload>(
          `${getWebApiURL()}/partRequest/${partRequestID}/updatePriceAndQty`,
          payload,
          'Unable to update quote. Please try again later',
        ),
    }),
  },
  externalDocument: {
    link: (externalDocumentID: number, convShareToken?: string) => ({
      queryKey: ['externalDocument', 'link', externalDocumentID],
      queryFn: () =>
        getRequestPromise<ExternalDocumentLinkResponse>(
          appendParamsToUrl(
            `${getWebApiURL()}/externalDocument/${externalDocumentID}/link`,
            { convShareToken },
          ),
        ),
    }),
  },
  order: {
    received: (queryParams: OrderReceivedOrdersQueryParams) => ({
      queryKey: ['order', 'received', queryParams],
      queryFn: () =>
        getRequestPromise<OrderReceivedOrdersResponse>(
          `${getWebApiURL()}/order/received`,
          { params: queryParams },
        ),
    }),
    placed: (queryParams: OrderPlacedOrdersQueryParams) => ({
      queryKey: ['order', 'placed', queryParams],
      queryFn: () =>
        getRequestPromise<OrderPlacedOrdersResponse>(
          `${getWebApiURL()}/order/placed`,
          { params: queryParams },
        ),
    }),
    displayData: (orderID: number) => ({
      queryKey: ['order', orderID, 'displayData'],
      queryFn: () =>
        getRequestPromise<OrderDisplayDataResponse>(
          `${getWebApiURL()}/order/${orderID}/displayData`,
        ),
      enabled: Boolean(orderID),
    }),
    setManualSupplierIntegration: (orderID: number) => ({
      mutationKey: ['order', orderID, 'setManualSupplierIntegration'],
      mutationFn: (payload: SetManualSupplierIntegrationPayload) =>
        postRequestPromise<void, SetManualSupplierIntegrationPayload>(
          `${getWebApiURL()}/order/${orderID}/manualSupplierIntegration`,
          payload,
        ),
    }),
    customerDetails: (orderID: number) => ({
      queryKey: ['order', orderID, 'customerDetails'],
      queryFn: () =>
        getRequestPromise<OrderCustomerDetailsResponse>(
          `${getWebApiURL()}/order/${orderID}/customerDetails`,
        ),
    }),
    recentBuyerOrders: (payload: OrderRecentBuyerOrdersPayload) => ({
      queryKey: ['order', 'recentBuyerOrders', payload],
      queryFn: () =>
        getRequestPromise<OrderRecentBuyerOrdersResponse>(
          `${getWebApiURL()}/order/recentBuyerOrders`,
          { params: payload },
        ),
    }),
    accept: (orderID: number) => ({
      mutationKey: ['order', 'accept'],
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/order/${orderID}/accept`,
          undefined,
          'There was an error updating order status, please try again',
        ),
    }),
    deny: (orderID: number) => ({
      mutationKey: ['order', 'deny'],
      mutationFn: (payload: OrderDenyPayload) =>
        postRequestPromise<void, OrderDenyPayload>(
          `${getWebApiURL()}/order/${orderID}/deny`,
          payload,
          'There was an error updating order status, please try again',
        ),
    }),
    partialShip: (orderID: number) => ({
      mutationKey: ['order', 'partialShip'],
      mutationFn: (payload: OrderPartialShipPayload) =>
        postRequestPromise<void, OrderPartialShipPayload>(
          `${getWebApiURL()}/order/${orderID}/partialShip`,
          payload,
          'There was an error updating order status, please try again',
        ),
    }),
    updateSupplierNotes: (orderID: number) => ({
      mutationKey: ['order', 'updateSupplierNotes'],
      mutationFn: (payload: OrderUpdateSupplierNotesPayload) =>
        postRequestPromise<void, OrderUpdateSupplierNotesPayload>(
          `${getWebApiURL()}/order/${orderID}/updateSupplierNotes`,
          payload,
          'There was an error updating the note. Please try again later',
        ),
    }),
    validateIntegrationException: () => ({
      mutationKey: ['order', 'validateIntegrationException'],
      mutationFn: ({
        orderID,
        integrationExceptionID,
      }: {
        orderID: number;
        integrationExceptionID: number;
      }) =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/order/${orderID}/validateAndResolveIntegrationException/${integrationExceptionID}`,
        ),
    }),
    integrationExceptions: (orderID: number) => ({
      queryKey: ['order', orderID, 'integrationExceptions'],
      queryFn: () =>
        getRequestPromise<OrderGetIntegrationExceptionsResponse>(
          `${getWebApiURL()}/order/${orderID}/integrationExceptions`,
        ),
    }),
    createSupplierErpOrder: (orderID: number) => ({
      mutationKey: ['order', orderID, 'createSupplierErpOrder'],
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/order/${orderID}/supplierErpOrder`,
        ),
    }),
    overrideIntegrationException: () => ({
      mutationKey: ['order', 'overrideIntegrationException'],
      mutationFn: ({
        orderID,
        integrationExceptionID,
      }: {
        orderID: number;
        integrationExceptionID: number;
      }) =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/order/${orderID}/overrideIntegrationException/${integrationExceptionID}`,
        ),
    }),
    applyIntegrationPaymentTerms: (
      orderID: number,
      integrationExceptionID: number,
    ) => ({
      mutationKey: [
        'order',
        orderID,
        'applyIntegrationPaymentTerms',
        integrationExceptionID,
      ],
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/order/${orderID}/applyIntegrationPaymentTerms/${integrationExceptionID}`,
        ),
    }),
    updateShippingMethod: (
      orderID: number,
      integrationExceptionID: number,
    ) => ({
      mutationFn: (payload: OrderUpdateShippingMethodPayload) =>
        postRequestPromise<void, OrderUpdateShippingMethodPayload>(
          `${getWebApiURL()}/order/${orderID}/updateShippingMethod/${integrationExceptionID}`,
          payload,
        ),
    }),
    setTaxAndShippingOverride: (orderID: number) => ({
      mutationKey: ['order', 'setTaxAndShippingOverride'],
      mutationFn: (payload: SetTaxAndShippingOverridePayload) =>
        postRequestPromise<void, SetTaxAndShippingOverridePayload>(
          `${getWebApiURL()}/order/${orderID}/setTaxAndShippingOverride`,
          payload,
          'There was an error updating the order. Please try again later',
        ),
    }),
  },
  orderItem: {
    queryKey: ['orderItem'],
    cancelItem: (orderItemID: number) => ({
      mutationKey: ['orderItem', 'cancelItem'],
      mutationFn: () =>
        deleteRequestPromise(
          `${getWebApiURL()}/orderItem/${orderItemID}/cancelItem`,
          {},
          'There was an error canceling order item, please try again',
        ),
    }),
    updateInstanceBuyer: (orderItemID: number) => ({
      mutationKey: ['orderItem', 'updateInstanceBuyer'],
      mutationFn: (payload: OrderItemUpdateInstanceBuyerPayload) =>
        postRequestPromise<void, OrderItemUpdateInstanceBuyerPayload>(
          `${getWebApiURL()}/orderItem/${orderItemID}/updateInstanceBuyer`,
          payload,
          'There was an error updating order details, please try again',
        ),
    }),
    updateInstanceSupplier: (orderItemID: number) => ({
      mutationKey: ['orderItem', 'updateInstanceSupplier'],
      mutationFn: (payload: OrderItemUpdateInstanceSupplierPayload) =>
        postRequestPromise<void, OrderItemUpdateInstanceSupplierPayload>(
          `${getWebApiURL()}/orderItem/${orderItemID}/updateInstanceSupplier`,
          payload,
          'There was an error updating order details, please try again',
        ),
    }),
  },
  payment: {
    queryKey: ['payment'],
    paymentMethods: {
      queryKey: ['payment', 'paymentMethods'],
      queryFn: () =>
        getRequestPromise<PaymentPaymentMethodsResponse>(
          `${getWebApiURL()}/payment/paymentMethods`,
        ).then((data) => data.paymentMethods),
    },
    paymentSetupData: {
      queryKey: ['payment', 'paymentSetupData'],
      queryFn: () =>
        getRequestPromise<PaymentPaymentSetupDataResponse>(
          `${getWebApiURL()}/payment/paymentSetupData`,
        ).then((data) => data.setupIntent),
    },
  },
  cart: {
    queryKey: ['cart'],
    displayData: {
      queryKey: ['cart', 'displayData'],
      queryFn: () =>
        getRequestPromise<CartDisplayDataResponse>(
          `${getWebApiURL()}/cart/displayData`,
        ).then((data) => data.cartParts ?? []),
    },
    navbar: {
      queryKey: ['navbar'],
      displayData: {
        /** Including 'cart' key, since the response depends on the cart content */
        queryKey: ['cart', 'navbar', 'index'],
        queryFn: () =>
          getRequestPromise<NavbarGetDataResponse>(
            `${getWebApiURL()}/navbar/data`,
          ),
      },
    },
  },
  cartPart: {
    queryKey: ['cartPart'],
    update: {
      mutationKey: ['cartPart', 'update'],
      mutationFn: ({ cartPartId, qty }: { cartPartId: number; qty: number }) =>
        postRequestPromise<UpdateCartPartResponse, UpdateCartPartRequest>(
          `${getRestApiURL()}/cartPart/${cartPartId}`,
          { qty },
        ).then((res) => res?.cartPart),
    },
    upsert: (partID: number) => ({
      mutationKey: ['cartPart', 'upsertCartPartViaPartSupplier', partID],
      mutationFn: async (
        payload: BatchUpsertCartPartViaPartSupplierPayloadData,
      ) => {
        const res = await postRequestPromise<
          UpsertCartPartViaPartSupplierResponse,
          BatchUpsertCartPartViaPartSupplierPayloadData
        >(`${getWebApiURL()}/cartPart/upsertCartPartViaPartSupplier`, payload);

        return res;
      },
    }),
    upsertViaQuote: () => ({
      mutationKey: ['cartPart', 'upsertViaQuote'],
      mutationFn: (payload: UpsertCartPartViaQuotePayload) =>
        postRequestPromise<
          UpsertCartPartsViaQuoteResponse,
          UpsertCartPartViaQuotePayload
        >(`${getWebApiURL()}/cartPart/upsertCartPartsViaQuote`, payload),
    }),
    delete: {
      mutationKey: ['cartPart', 'delete'],
      mutationFn: (partId: number) =>
        deleteRequestPromise(`${getRestApiURL()}/cartPart/${partId}`),
    },
  },
  address: {
    queryKey: ['address'],
    get: (type: string) => ({
      queryKey: ['address', 'list', type],
      queryFn: () =>
        getRequestPromise<AddressFindAddressResponse>(
          `${getRestApiURL()}/address?type=${type}`,
        ),
    }),
    update: (addressId: number) => ({
      mutationKey: ['address', 'update', addressId],
      mutationFn: (payload: AddressUpdateAddressPayload) =>
        postRequestPromise<
          AddressUpdateAddressResponse,
          AddressUpdateAddressPayload
        >(`${getRestApiURL()}/address${addressId}`, payload),
    }),
    create: {
      mutationKey: ['address', 'create'],
      mutationFn: (payload: AddressCreateAddressPayload) =>
        postRequestPromise<
          AddressCreateAddressResponse,
          AddressCreateAddressPayload
        >(`${getRestApiURL()}/address`, payload),
    },
  },
  certificate: {
    queryKey: ['certificate'],
    list: (params: CertificateFindCertificatesPayload) => ({
      queryKey: ['certificate', 'list', params],
      queryFn: () =>
        getRequestPromise<CertificateFindCertificatesResponse>(
          `${getRestApiURL()}/certificate`,
          { params },
        ),
    }),
    upload: {
      mutationKey: ['certificate', 'upload'],
      mutationFn: (payload: CertificateUploadRequest<File>) =>
        uploadRequestPromise<void, CertificateUploadRequest<File>>(
          `${getWebApiURL()}/certificate/upload`,
          payload,
        ),
    },
  },
  shippingAccount: {
    queryKey: ['shippingAccount'],
    list: {
      queryKey: ['shippingAccount', 'list'],
      queryFn: () =>
        getRequestPromise<ShippingAccountFindShippingAccountResponse>(
          `${getRestApiURL()}/shippingAccount`,
        ).then((res) => res?.shippingAccounts),
    },
    create: {
      mutationKey: ['shippingAccount', 'create'],
      mutationFn: (payload: ShippingAccountCreateShippingAccountPayload) =>
        postRequestPromise<
          ShippingAccountCreateShippingAccountResponse,
          ShippingAccountCreateShippingAccountPayload
        >(`${getRestApiURL()}/shippingAccount`, payload),
    },
    delete: {
      mutationKey: ['shippingAccount', 'delete'],
      mutationFn: (id: number) =>
        deleteRequestPromise<void>(`${getRestApiURL()}/shippingAccount/${id}`),
    },
  },
  search: {
    queryKey: ['search'],
    partAutocompleteOmni: (query: string) => ({
      queryKey: ['search', 'partAutocompleteOmni', query],
      queryFn: () =>
        getRequestPromise<SearchPartAutocompleteOmniResponse>(
          `${getWebApiURL()}/search/partAutocompleteOmni`,
          { params: { query } },
        ),
    }),
    partTableData: (params: SearchPartSearchTableDataPayload) => ({
      queryKey: ['search', 'partTableData', params],
      queryFn: () =>
        getRequestPromise<SearchPartSearchTableDataResponse>(
          `${getWebApiURL()}/search/partTableData`,
          { params },
        ),
    }),
    partCategoryAutocompleteOmni: (query: string) => ({
      queryKey: ['search', 'partCategoryAutocompleteOmni', query],
      queryFn: () =>
        getRequestPromise<SearchPartCategoryAutocompleteOmniResponse>(
          `${getWebApiURL()}/search/partCategoryAutocompleteOmni`,
          { params: { query } },
        ),
    }),
    partAutocomplete: (params: { query?: string; supplierID?: number }) => ({
      queryKey: ['search', 'partAutocomplete', params],
      queryFn: () =>
        getRequestPromise<SearchPartAutocompleteResponse>(
          `${getWebApiURL()}/search/partAutocomplete`,
          { params },
        ),
    }),
    partCategoryData: (partCategoryID: number) => ({
      queryKey: ['search', 'partCategoryData', partCategoryID],
      queryFn: () =>
        getRequestPromise<SearchPartCategoryDataResponse>(
          `${getWebApiURL()}/search/partCategoryData/${partCategoryID}`,
        ),
    }),
    nonparametricPartData: (params: SearchNonparametricPartDataPayload) => ({
      queryKey: ['search', 'nonparametricPartData', params],
      queryFn: () =>
        getRequestPromise<SearchNonparametricPartDataResponse>(
          `${getWebApiURL()}/search/nonparametricPartData/`,
          { params },
        ),
    }),
    parametricPartData: (params: SearchParametricPartDataPayload) => ({
      queryKey: ['search', 'parametricPartData', params],
      queryFn: () =>
        getRequestPromise<SearchParametricPartDataResponse>(
          `${getWebApiURL()}/search/parametricPartData/`,
          { params },
        ),
    }),
  },
  quote: {
    queryKey: ['quote'],
    displayData: (quoteID: number, quoteShareToken?: string) => ({
      queryKey: ['quote', quoteID, 'displayData'],
      queryFn: () =>
        getRequestPromise<QuoteDisplayDataResponse>(
          `${getWebApiURL()}/quote/${quoteID}/displayData`,
          { params: { quoteShareToken } },
        ),
      enabled: Boolean(quoteID),
    }),
    subscribe: (quoteID: number, payload: QuoteSubscribePayload) => ({
      queryKey: ['quote', 'subscribe', quoteID],
      queryFn: () =>
        getSocketRequestPromise<QuoteSubscribePayload, void>(
          `${getWebApiURL()}/quote/${quoteID}/subscribe`,
          payload,
        ),
    }),
    unsubscribe: (quoteID: number, payload: QuoteUnsubscribePayload) => ({
      queryKey: ['quote', 'unsubscribe', quoteID],
      queryFn: () =>
        getSocketRequestPromise<QuoteUnsubscribePayload, void>(
          `${getWebApiURL()}/quote/${quoteID}/unsubscribe`,
          payload,
        ),
    }),
    noBid: (quoteID: number) => ({
      mutationKey: ['quote', quoteID, 'noBid'],
      mutationFn: (payload: QuoteNoBidPayload) =>
        postRequestPromise<void, QuoteNoBidPayload>(
          `${getWebApiURL()}/quote/${quoteID}/noBid`,
          payload,
        ),
    }),
    yesBid: (quoteID: number) => ({
      mutationKey: ['quote', quoteID, 'yesBid'],
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/quote/${quoteID}/yesBid`,
        ),
    }),
    submit: (quoteID: number) => ({
      mutationKey: ['quote', quoteID, 'submit'],
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/quote/${quoteID}/submit`,
        ),
    }),
    reopen: (quoteID: number) => ({
      mutationKey: ['quote', quoteID, 'reopen'],
      mutationFn: () =>
        postRequestPromise<void, void>(
          `${getWebApiURL()}/quote/${quoteID}/reopen`,
        ),
    }),
    updateQuoteNote: (quoteID: number) => ({
      mutationKey: ['quote', quoteID, 'updateQuoteNote'],
      mutationFn: (payload: QuoteUpdateQuoteNotePayload) =>
        postRequestPromise<void, QuoteUpdateQuoteNotePayload>(
          `${getWebApiURL()}/quote/${quoteID}/updateQuoteNote`,
          payload,
        ),
    }),
    updateNotesSupplier: (quoteID: number) => ({
      mutationKey: ['quote', quoteID, 'updateNotesSupplier'],
      mutationFn: (payload: QuoteUpdateNotesSupplierPayload) =>
        postRequestPromise<void, QuoteUpdateNotesSupplierPayload>(
          `${getWebApiURL()}/quote/${quoteID}/updateNotesSupplier`,
          payload,
        ),
    }),
    updateAssignee: (quoteID: number) => ({
      mutationKey: ['quote', quoteID, 'updateAssignee'],
      mutationFn: (payload: QuoteUpdateAssigneePayload) =>
        postRequestPromise<void, QuoteUpdateAssigneePayload>(
          `${getWebApiURL()}/quote/${quoteID}/updateAssignee`,
          payload,
        ),
    }),
    updateValidityDuration: (quoteID: number) => ({
      mutationKey: ['quote', quoteID, 'updateValidityDuration'],
      mutationFn: (payload: QuoteUpdateValidityDurationPayload) =>
        postRequestPromise<void, QuoteUpdateValidityDurationPayload>(
          `${getWebApiURL()}/quote/${quoteID}/updateValidityDuration`,
          payload,
        ),
    }),
    createMultipleWithPart: {
      mutationFn: (payload: QuoteCreateMultipleWithPartPayload) =>
        postRequestPromise<
          QuoteCreateMultipleWithPartResponse,
          QuoteCreateMultipleWithPartPayload
        >(`${getWebApiURL()}/quote/createMultipleWithPart`, payload),
    },
    createAsAnonymous: {
      mutationFn: (payload: QuoteCreateAsAnonymousPayload) =>
        postRequestPromise<
          QuoteCreateAsAnonymousResponse,
          QuoteCreateAsAnonymousPayload
        >(`${getWebApiURL()}/quote/createAsAnonymous`, payload),
    },
    listBuyerData: (params?: QuoteListBuyerDataPayload) => ({
      queryKey: ['quote', 'listBuyerData', params],
      queryFn: () =>
        getRequestPromise<QuoteListBuyerDataResponse>(
          `${getWebApiURL()}/quote/listBuyerData`,
          { params },
        ),
    }),
    listSupplierData: (params?: QuoteListSupplierDataPayload) => ({
      queryKey: ['quote', 'listSupplierData', params],
      queryFn: () =>
        getRequestPromise<QuoteListSupplierDataResponse>(
          `${getWebApiURL()}/quote/listSupplierData`,
          { params },
        ),
    }),
    recentBuyerQuotes: (payload: QuoteRecentBuyerQuotesPayload) => ({
      queryKey: ['quote', 'recentBuyerQuotes', payload],
      queryFn: () =>
        getRequestPromise<QuoteRecentBuyerQuotesResponse>(
          `${getWebApiURL()}/quote/recentBuyerQuotes`,
          { params: payload },
        ),
    }),
    recentContactQuotes: (payload: QuoteRecentContactQuotesPayload) => ({
      queryKey: ['quote', 'recentContactQuotes', payload],
      queryFn: () =>
        getRequestPromise<QuoteRecentContactQuotesResponse>(
          `${getWebApiURL()}/quote/recentContactQuotes`,
          { params: payload },
        ),
    }),
  },
  quoteFee: {
    queryKey: ['quoteFee'],
    create: () => ({
      mutationKey: ['quoteFee', 'create'],
      mutationFn: (payload: QuoteFeeCreateQuoteFeePayload) =>
        postRequestPromise<
          QuoteFeeCreateQuoteFeeResponse,
          QuoteFeeCreateQuoteFeePayload
        >(`${getRestApiURL()}/quoteFee`, payload),
    }),
    update: () => ({
      mutationKey: ['quoteFee', 'update'],
      mutationFn: ({
        quoteFeeID,
        payload,
      }: {
        quoteFeeID: number;
        payload: QuoteFeeUpdateQuoteFeePayload;
      }) =>
        postRequestPromise<
          QuoteFeeUpdateQuoteFeeResponse,
          QuoteFeeUpdateQuoteFeePayload
        >(`${getRestApiURL()}/quoteFee/${quoteFeeID}`, payload),
    }),
    delete: () => ({
      mutationKey: ['quoteFee', 'delete'],
      mutationFn: (quoteFeeID: number) =>
        deleteRequestPromise<void>(`${getRestApiURL()}/quoteFee/${quoteFeeID}`),
    }),
  },
  pendingUser: {
    queryKey: ['pendingUser'],
    setReferralUrl: {
      mutationKey: ['pendingUser', 'setReferralUrl'],
      mutationFn: (payload: PendingUserSetReferralUrlPayload) =>
        postRequestPromise<void, PendingUserSetReferralUrlPayload>(
          `${getWebApiURL()}/pendingUser/setReferralUrl`,
          payload,
        ),
    },
  },
  userInviteToken: {
    queryKey: ['userInviteToken'],
    findOne: (token: string) => ({
      queryKey: ['userInviteToken', 'findOne', token],
      queryFn: () =>
        getRequestPromise<UserInviteTokenFindOneUserInviteTokenResponse>(
          `${getRestApiURL()}/userInviteToken/${token}`,
        ),
    }),
  },
  internalAdmin: {
    queryKey: ['internalAdmin'],
    part: {
      queryKey: ['internalAdmin', 'part'],
      salesData: (partID: number, refetchFromSuppliers: boolean) => ({
        queryKey: ['internalAdmin', 'part', partID, 'salesData'],
        queryFn: () =>
          getRequestPromise<IAPartSearchSalesDataResponse>(
            `${getInternalAdminApiPrefix()}/part/${partID}/salesData`,
            {
              params: {
                refetchFromSuppliers,
              },
            },
          ),
      }),
    },
    externalPart: {
      queryKey: ['internalAdmin', 'externalPart'],
      searchByPartId: (partID: number) => ({
        queryKey: ['internalAdmin', 'externalPart', 'searchByPartId', partID],
        queryFn: () =>
          getRequestPromise<IAPartSearchExternalPartByPartIdResponse>(
            `${getInternalAdminApiPrefix()}/externalPart/searchByPartId`,
            { params: { partId: partID } },
          ),
      }),
      searchByQuery: (query: string) => ({
        queryKey: ['internalAdmin', 'externalPart', 'searchByQuery', query],
        queryFn: () =>
          getRequestPromise<IAPartSearchExternalPartByQueryResponse>(
            `${getInternalAdminApiPrefix()}/externalPart/searchByQuery`,
            { params: { query } },
          ),
      }),
    },
    userEnrichmentData: (email: string) => ({
      queryKey: ['internalAdmin', 'userEnrichmentData', email],
      queryFn: () =>
        getRequestPromise<IAUserEnrichmentInfoResponse>(
          `${getInternalAdminApiPrefix()}/userEnrichmentData`,
          { params: { email } },
        ),
    }),
  },
  integration: {
    erpShippingMethods: {
      queryKey: ['integration', 'erpShippingMethods'],
      queryFn: () =>
        getRequestPromise<GetErpShippingMethodsResponse>(
          `${getWebApiURL()}/integration/erpShippingMethods`,
        ),
    },
  },
  passwordToken: {
    queryKey: ['passwordToken'],
    findOne: (token: string) => ({
      queryKey: ['passwordToken', 'findOne', token],
      queryFn: () =>
        getRequestPromise<PasswordTokenFindOneResponse>(
          `${getRestApiURL()}/passwordToken/${token}`,
        ),
    }),
  },
  resetPassword: {
    mutationFn: (payload: ResetPasswordPayload) =>
      postRequestPromise<void, ResetPasswordPayload>(
        `${getWebApiURL()}/resetPassword`,
        payload,
      ),
  },
  couponCode: {
    queryKey: ['couponCode'],
    get: (couponCodeID: number) => ({
      queryKey: ['couponCode', couponCodeID],
      queryFn: () =>
        getRequestPromise<CouponCodeCreateCouponCodeResponse>(
          `${getRestApiURL()}/couponCode/${couponCodeID}`,
        ),
    }),
    create: {
      mutationFn: (payload: CouponCodeCreateCouponCodePayload) =>
        postRequestPromise<void, CouponCodeCreateCouponCodePayload>(
          `${getWebApiURL()}/couponCode`,
          payload,
        ),
    },
    update: {
      mutationFn: ({
        couponCodeID,
        payload,
      }: {
        couponCodeID: number;
        payload: CouponCodeUpdateCouponCodePayload;
      }) =>
        postRequestPromise<void, CouponCodeUpdateCouponCodePayload>(
          `${getWebApiURL()}/couponCode/${couponCodeID}`,
          payload,
        ),
    },
    list: {
      queryKey: ['couponCode', 'list'],
      queryFn: () =>
        getRequestPromise<CouponCodeListCouponCodesResponse>(
          `${getRestApiURL()}/couponCode`,
        ),
    },
    validate: {
      mutationFn: (payload: CouponCodeValidateCouponCodePayload) =>
        postRequestPromise<
          CouponCodeValidateCouponCodeResponse,
          CouponCodeValidateCouponCodePayload
        >(`${getWebApiURL()}/couponCode/validate`, payload),
    },
  },
  userPaymentMethod: {
    createUserPaymentMethod: (host: string, tenantAlias: TenantAlias) => ({
      mutationFn: (payload: UserPaymentMethodCreateUserPaymentMethodPayload) =>
        postRequestPromise<
          void,
          UserPaymentMethodCreateUserPaymentMethodPayload
        >(
          `${getWebApiURLForTenant(tenantAlias, host)}/userPaymentMethod`,
          payload,
        ),
    }),
  },
  partCategory: {
    footerCategories: {
      queryKey: ['partCategory', 'footerCategories'],
      queryFn: () =>
        getRequestPromise<PartCategoryFooterCategoriesResponse>(
          `${getWebApiURL()}/partCategory/footerCategories`,
        ),
    },
    trendingCategories: {
      queryKey: ['partCategory', 'trendingCategories'],
      /* TODO: queryFn can't have argument, it should be passed in trendingCategories(timeout) otherwise
       it can't be used in useQuery() because of a cryptic type mismatch */
      queryFn: (timeout = 5000) =>
        getRequestPromise<PartCategoryTrendingCategoriesResponse>(
          `${getWebApiURL()}/partCategory/trendingCategories`,
          {
            timeout: timeout,
          },
        ).then((res) => res.trendingCategories),
    },
    searchCategories: {
      queryKey: ['partCategory', 'searchCategories'],
      queryFn: () =>
        getRequestPromise<PartCategorySearchCategoriesResponse>(
          `${getWebApiURL()}/partCategory/searchCategories`,
        ).then((res) => res.searchCategories),
    },
    findOnePartCategory: (partCategoryID: number) => ({
      queryKey: ['partCategory', 'findOnePartCategory', partCategoryID],
      queryFn: () =>
        getRequestPromise<PartCategoryFindOnePartCategoryResponse>(
          `${getRestApiURL()}/partCategory/${partCategoryID}`,
        ),
    }),
  },
  salesTerritory: {
    queryKey: ['salesTerritory'],
    setSalesTerritoryBatch: {
      mutationFn: (payload: SalesTerritorySetSalesTerritoryBatchPayload) =>
        postRequestPromise<void, SalesTerritorySetSalesTerritoryBatchPayload>(
          `${getWebApiURL()}/salesTerritory/setSalesTerritoryBatch`,
          payload,
        ),
    },
  },
  watchObject: {
    queryKey: ['watchObject'],
    setEnabled: (objectType: string, objectID: number) => ({
      mutationFn: (payload: { enabled: boolean }) =>
        postRequestPromise<void, { enabled: boolean }>(
          `${getWebApiURL()}/watchObject/${objectType}/${objectID}/setEnabled`,
          payload,
        ),
    }),
  },
  supplierPrice: {
    queryKey: ['supplierPrice'],
    addPriceForPart: () => ({
      mutationFn: (payload: SupplierPriceAddPriceForPartPayload) =>
        postRequestPromise<
          SupplierPriceAddPriceForPartResponse,
          SupplierPriceAddPriceForPartPayload
        >(`${getWebApiURL()}/supplierPrice/addPriceForPart`, payload),
    }),
    uploadPartsPricesFile: () => ({
      mutationFn: (payload: SupplierPriceUploadPartsPricesFilePayload) =>
        uploadRequestPromise<void, SupplierPriceUploadPartsPricesFilePayload>(
          `${getWebApiURL()}/supplierPrice/uploadPartsPricesFile`,
          payload,
        ),
    }),
  },
  notificationPolicy: {
    unsubscribe: {
      mutationFn: (payload: NotificationPolicyUnsubscribePayload) =>
        postRequestPromise<void, NotificationPolicyUnsubscribePayload>(
          `${getWebApiURL()}/notificationPolicy/unsubscribe`,
          payload,
        ),
    },
  },
};
